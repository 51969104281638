import React from "react";
import AppLayout from "./layout";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { Typography } from "@material-ui/core";

export default function SignIn() {
  const history = useHistory();

  const onClose = () => {
    history.goBack();
    return;
  };


  return <AppLayout title={" Cancellation & Delivery Policy"} onClick={onClose} style={{overflowY:'scroll'}}>
    <div className={"container"}>
    
        <h1 className={"header__address"}>{"Delivery & Shipping Policy"}</h1>
        <Typography> 
          This application will not deal or provide any services or products to any of OFAC (Office of Foreign Assets Control) sanctions countries in accordance with the law of UAE.
        </Typography>

        <br></br>
        <h1 className={"header__address"}>{"Cancellation & Replacement Policy"}</h1>
        <Typography style={{marginBottom:"20vh"}}> 
          If you are not entirely happy with your items, please speak to a member of our team for an exchange or refund.
        </Typography >

    </div>
  </AppLayout>;
}
