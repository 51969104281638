import React from "react";
import AppLayout from "./layout";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { Typography } from "@material-ui/core";

export default function SignIn() {
  const history = useHistory();

  const onClose = () => {
    history.goBack();
    return;
  };


  return <AppLayout title={"Terms and Conditions"} onClick={onClose} style={{overflowY:'scroll'}}>
    <div className={"container"}>
    
        <h1 className={"header__address"}>{"About Us"}</h1>
        <Typography> 
          M&S is a leading British retailer bringing quality, great value food, clothing, and homeware to millions of customers around the world.
          <br></br>Our food is known for its quality, freshness, and innovation credentials.
          <br></br>Our Clothing is Contemporary and wearable style at great value.
          <br></br>We have more than 1,400 stores across 57 countries and over 50 international websites.
        </Typography>

        <br></br>
        <h1 className={"header__address"}>{"Privacy Policy"}</h1>
        <Typography> 
          All credit/debit cards details and personally identifiable information will NOT be stored, sold, shared, rented or leased to any third parties.
          <br></br>The Website Policies and Terms & Conditions may be changed or updated occasionally to meet the requirements and standards. 
          Therefore the Customers’ are encouraged to frequently visit these sections in order to be updated about the changes on the website. 
          Modifications will be effective on the day they are posted.
        </Typography>

        <br></br>
        <h1 className={"header__address"}>{"Governing Law and Jurisdiction"}</h1>

        <Typography> 
          Any purchase, dispute or claim arising out of or in connection with this website shall be governed and construed in accordance with the laws of UAE.
        </Typography>

        <br></br>
        <h1 className={"header__address"}>{"Country of merchant domicile"}</h1>

        <Typography> 
          The merchant domicile is UAE.
        </Typography>

        <br></br>
        <h1 className={"header__address"} >{"Address & Contact Details"}</h1>

        <Typography> 
          Address: Dubai, Marks and Festival Café – Festival Tower.
        </Typography>
        <Typography style={{marginBottom:"20vh"}}> 
          Contact: Stephen Edwards, +971588775788.
        </Typography>



    </div>
  </AppLayout>;
}
