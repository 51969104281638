import React from "react";
import AppLayout from "./layout";
import "../styles/process.css";
import LocaleContext from "../context/LocaleContext";
import Languages from "../languages/index";
import { useHistory } from "react-router-dom";

export default function CloseDoor() {
  const history = useHistory();
  const { locale } = React.useContext(LocaleContext);
  const [expired, setExpired] = React.useState("");

  const changeToHome = () => {
    history.push("/home");
  };
  React.useEffect(() => {
    setTimeout(() => {
      let _expired = {
        pt:
          "Parece que a operação está a demorar mais que o previsto. Poderá consultar o seu recibo assim que concluída.",
        en:
          "It seems that the operation is taking longer than anticipated. You will be able to consult your receipt as soon as it is completed.",
      };
      setTimeout(() => {
        changeToHome();
      }, 2500);
      setExpired(_expired[locale]);
    }, 40000);
  }, [expired]);

  return (
    <AppLayout
      title={""}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="payment-loader">
        <div className="pad">
          <div className="chip"></div>
          <div className="line line1"></div>
          <div className="line line2"></div>
        </div>
        <div
          className="loader-text"
          dangerouslySetInnerHTML={{
            __html: Languages[locale].closeDoor(expired),
          }}
        ></div>
      </div>
    </AppLayout>
  );
}
